.Toast {
  animation: slide-down 100ms ease-in-out;
  background: #e8e8e8;
  color: #555;
  padding: 8px 25px;
  border-radius: 2px;
  box-shadow: 0 0 6px 1px #0001;
  position: fixed;
  top: 30px;
  left: 50%;
  font-size: 14px;
  transform: translateX(-50%);
  z-index: 5000;
  white-space: pre-wrap;
  @media screen and (max-width: 940px) {
    font-size: 16px;
    max-width: 90vw;
  }
  @media screen and (max-width: 500px) {
    width: calc(100vw - 30px);
  }
}