.Calendar {
  .rbc-day-bg {
    cursor: pointer;
  }
  .rbc-toolbar button:hover {
    background-color: #fafafa;
  }
  .rbc-toolbar button:focus {
    background-color: #f5f5f5;
  }
  .rbc-toolbar button:active:hover,
  .rbc-toolbar button:active:focus,
  .rbc-toolbar button.rbc-active:hover,
  .rbc-toolbar button.rbc-active:focus {
    background-color: #fafafa;
    color: #4668f4;
  }
  .rbc-toolbar button:active,
  .rbc-toolbar button.rbc-active {
    box-shadow: inset 0 3px 5px rgba(0, 0, 0, 0.04);
    background-color: #f5f5f5;
    color: #4668f4;
  }
  &.readonly {
    .rbc-day-bg {
      cursor: initial;
    }
  }
}

@media screen and (max-width: 999px) {
  .Calendar {
    .rbc-toolbar {
      flex-direction: column;
      align-items: center;
      .rbc-toolbar-label {
        order: 0;
        font-size: 18px;
        font-weight: 600;
      }
      .rbc-btn-group:first-child {
        order: 1;
      }
      .rbc-btn-group {
        margin-top: 5px;
      }
      button {
        font-size: 15px;
      }
    }
  }
}